.landing-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-family: 'Manrope', sans-serif;
    background-color: #f7f7f7;
    color: #333;
    position: relative;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    transition: opacity 0.3s ease;
}

.overlay-content {
    text-align: center;
    padding: 20px;
    max-width: 600px;
}

.overlay-content p {
    margin: 10px 0;
    font-size: 28px;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.overlay-content p:last-child {
    font-weight: 400;
    font-style: italic;
    font-size: 22px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}