.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    overflow: hidden;
    touch-action: none; /* Prevents scaling issues related to touch */
}

.overlay-content {
    text-align: center;
    padding: 20px;
    max-width: 600px;
    width: 100%;
    box-sizing: border-box;
    font-size: 3.5vw; /* Adjusted to use viewport width units */
}

.overlay-content p {
    margin: 10px 0;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.overlay-content p:last-child {
    font-weight: 400;
    font-style: italic;
    font-size: 2.5vw; /* Use viewport width for consistent scaling */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 768px) {
    .overlay-content p {
        font-size: 28px; /* Default to original size on larger screens */
    }

    .overlay-content p:last-child {
        font-size: 22px; /* Default to original size on larger screens */
    }
}
