/* src/App.css */
body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  background-color: #f7f7f7;
  color: #333;
}

.App {
  position: relative;
}

.navigation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.progress-bar {
  display: flex;
  width: 80%;
  margin-bottom: 10px;
}

.progress-segment {
  flex-grow: 1;
  height: 5px;
  background-color: lightgray;
  margin: 0 2px;
}

.progress-segment.active {
  background-color: gray;
}

.navigation-text {
  display: flex;
  justify-content: space-between;
  width: 80%;
  flex-wrap: wrap; /* Allows wrapping of text if necessary */
}

.navigation-link {
  text-decoration: none;
  color: lightgray;
  font-size: 14px;
  white-space: nowrap; /* Prevents the text from wrapping */
  margin: 0 5px; /* Adds some spacing between links */
}

.navigation-link:hover {
  color: black;
}

.content-container {
  padding: 20px;
}

/* Media Queries for Mobile */
@media only screen and (max-width: 768px) {
  .navigation-container {
    margin-top: 10px;
  }

  .progress-bar {
    width: 90%; /* Adjust width for smaller screens */
    margin-bottom: 8px;
  }

  .progress-segment {
    height: 4px; /* Reduce height for smaller screens */
    margin: 0 1px;
  }

  .navigation-text {
    width: 100%; /* Use the full width for navigation text */
    justify-content: center; /* Center the navigation links */
    flex-wrap: wrap; /* Allows the links to wrap onto the next line if necessary */
  }

  .navigation-link {
    font-size: 12px; /* Slightly reduce font size for better fit */
    margin: 2px 4px; /* Adjust margin to fit more links horizontally */
    white-space: nowrap; /* Ensure links don't break into multiple lines */
  }

  .content-container {
    padding: 15px; /* Reduce padding for smaller screens */
  }
}
