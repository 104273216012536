/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  background-color: #f7f7f7;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}